const styles = [
  {
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "-100"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 65
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": "50"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "-100"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "30"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "40"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#ffff00"
      },
      {
        "lightness": -25
      },
      {
        "saturation": -97
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": -25
      },
      {
        "saturation": -100
      }
    ]
  }
];

try {

  const LAT = 52.254919;
  const LNG = 21.0453324;
  let styledMapType: google.maps.StyledMapType;
  let map: google.maps.Map;
  let markers: google.maps.Marker[] = [];
  let icon: google.maps.Icon;

  const iconUrl = '/assets/img/full-pin.svg';

  window['initMap'] = function() {
    styledMapType = new google.maps.StyledMapType(
      styles as any,
      {name: 'Styled Map'}
    );
    map = new google.maps.Map($('#map-container')[0], {
      center: {lat: LAT, lng: LNG},
      zoom: 17,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false
    });
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
    icon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(82, 82),
      anchor: new google.maps.Point(41, 82)
    };
    markers.push(
      new google.maps.Marker({
        position: new google.maps.LatLng(LAT, LNG),
        icon: icon,
        map
      })
    );
    // markers[0].addListener('click', function () {
    //   // Nothing to see here
    // })
  }

} catch (e) {
  console.error('Map logic initialization error', e);
}