try {

  // Get elements
  const container = $('.section.testimonials .quotes-container');
  const testimonials = container.children('blockquote');
  const controlButtons = $('.section.testimonials .controls');

  // View update
  const updateView = () => {
    testimonials.removeClass('active');
    testimonials.eq(currentIndex).addClass('active');
  };

  // Read initial state
  const count = testimonials.length;
  let currentIndex = testimonials.index('.active');

  const doChange = (left: boolean) => {
    if (left) {
      currentIndex--;
    } else {
      currentIndex++;
    }
    if (currentIndex < 0) {
      currentIndex = count - 1;
    } else if (currentIndex > count-1) {
      currentIndex = 0;
    }
    updateView();
  };

  // Events
  controlButtons.on('click', function () {
    if (this.classList.contains('left')) {
      doChange(true);
    } else {
      doChange(false);
    }
  });

  // Swipe
  const cHammer = new Hammer(container[0]);
  cHammer.on('swiperight', (e) => {
    doChange(true);
  });
  cHammer.on('swipeleft', (e) => {
    doChange(false);
  });

} catch (e) {
  console.error('Testimonials logic initialization error', e);
}