try {

  const container = $('#popup-container');
  const button = container.find('button');
  const invokers = $(' .open-popup');

  let isActive;
  let $checkbox: JQuery<HTMLElement> | undefined;

  const updateActiveness = () => {
    isActive = container.hasClass('active');
  };
  updateActiveness();

  const dismissDialog = () => {
    container.removeClass('active');
    $checkbox = undefined;
    return updateActiveness();
  };

  container.on('click', function() {
    dismissDialog();
  });

  button.on('click', function(e) {
    if (!$checkbox) {
      dismissDialog();
    }
    e.stopPropagation();
    if ($checkbox) {
      ($checkbox[0] as HTMLInputElement).checked = true;
      ($checkbox[0] as HTMLInputElement).value = 'on';
    }
    dismissDialog();
  });

  invokers.on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const $ch = $(this)
      .parents('.checkbox-input')
      .find('input[type=checkbox]');
    if (!$ch) return;
    $checkbox = $ch;
    container.addClass('active');
  })

} catch (e) {
  console.error('Popup logic initialization error', e);
}