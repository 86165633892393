try {
  const wnd = $(window);

  const getFormValues = (inputs: JQuery<HTMLElement>): any => {
    const obj = {};
    inputs.each(function() {
      let val = (this as HTMLInputElement).value;
      if (!val && this.tagName.toLowerCase() === 'textarea') {
        val = this.innerHTML.trim();
      }
      obj[$(this).attr('name') as string] = val;
    });
    return obj;
  };

  let formData: any = {};

  // Watch max and min
  const forms = $('form');

  // First form submit event
  forms.on('submit', function(e) {

    const $this = $(this);
    const values = getFormValues($this.find('input, textarea'));
    if (values.agreement !== 'on') return;

    $.post({
      url: '/submit-form',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(values),
      success: (data: {success: boolean; error?: string}) => {

        console.log(data);
        if (data.success) {
          $this.css('align-self', 'flex-start');
          $this.css('border', '1px solid');
          $this.addClass('done');
          $this.html('<div class="tip">Formularz wysłany poprawnie.<Br/><strong>Dziękuję!</strong></div>');
        } else {
          alert(data.error);
          ($this[0] as HTMLFormElement).reset();
        }
      }
    });

    return false;
  });

} catch (e) {
  console.error('Form logic initialization error', e);
}