try {

  const anchors = $('[data-anchor]');

  anchors.each(function () {

    const $el = $(this);
    $el.on('click', function() {
      const selector = $(this).data('anchor');
      if (!selector) return;
      const anchorEl = $(selector);
      if (!anchorEl) return;
      const topLeft = anchorEl.offset();
      if (!topLeft) return;
      $([document.documentElement, document.body]).animate({
        scrollTop: topLeft.top
      }, 1000);
    });
  })

} catch (e) {
  console.error('Anchors logic initialization error', e);
}